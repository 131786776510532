import Vue from 'vue'

export const MLAppSisfrete = '3334389175433285'
export const userKey = '__webtracking'
export const baseApiUrl = 'https://api.cliente.sandbox.sisfrete.com.br/api/v1'
// export const baseApiUrl = 'http://localhost:88/api/v1'
export const baseApiUrlPersys = 'https://api3.sisfrete.com.br/api'

export function showError(e) {
    if(e && e.data && e.data.mensagem) {
        Vue.toasted.global.defaultError({ mensagem : e.data.mensagem })
    } else if(typeof e === 'string') {
        Vue.toasted.global.defaultError({ mensagem : e })
    } else {
        Vue.toasted.global.defaultError()
    }
}

export function showSuccess(e) {
    if(e && e.data && e.data.mensagem) {
        Vue.toasted.global.defaultSuccess({ mensagem : e.data.mensagem })
    } else if(typeof e === 'string') {
        Vue.toasted.global.defaultSuccess({ mensagem : e })
    } else {
        Vue.toasted.global.defaultSuccess()
    }
}

export default { userKey, baseApiUrl, baseApiUrlPersys, showError, showSuccess }